import React, { useState, useEffect } from "react"
import { Form } from "../style/globalStyles"
import Switch from "react-switch"
import Moment from "moment"

export const SingleOrganisation = ({ organisationData = {} }) => {
  const [state, setState] = useState(organisationData)
  // console.log(organisationData)
  const [enable, setEnable] = useState(false)
  useEffect(() => {
    if (organisationData) {
      setState(organisationData)
    }
    // console.log("useEffect state", state)
  }, [organisationData])

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
    setEnable(true)
  }
  const handleActiveChange = (value) => {
    setState({ ...state, isActive: value })
    setEnable(true)
  }
  const handlePrimaryChange = (value) => {
    setState({ ...state, isPrimary: value })
    setEnable(true)
  }
  //   const handleSubscribeChange = (value) => {
  //     setState({ ...state, isSubscription: value })
  //     setEnable(true)
  //   }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (enable) {
      console.log("organisation update api request can be sent")
    }
    return
  }

  return (
    <Form onSubmit={handleSubmit} style={{}}>
      <div style={{ paddingRight: "1rem" }}>
        <div
          style={{
            marginBottom: "1rem",
            display: "flex",
            flexDirection: "row",
            // width: "100%",
            borderBottom: "1px solid lightgray",

            flexWrap: "wrap",
          }}
        >
          <label htmlFor="organisationId">
            Organisation ID
            <p>{organisationData.organizationId}</p>
          </label>
          <label htmlFor="organizationName">
            Organisation Name
            <p>
              {organisationData.organizationName
                ? organisationData.organizationName
                : "NA"}
            </p>
          </label>
          <label>
            Organisation Type
            <p>
              {organisationData.organizationType
                ? organisationData.organizationType
                : "NA"}
            </p>
          </label>

          <label htmlFor="startDate">
            Start Date
            <p>
              {organisationData.startDate
                ? Moment(organisationData.startDate).format("DD MMM YYYY")
                : "NA"}
            </p>
          </label>
          <label htmlFor="endDate">
            End Date
            <p>
              {organisationData.endDate
                ? Moment(organisationData.endDate).format("DD MMM YYYY")
                : "NA"}
            </p>
          </label>
          <label htmlFor="organisationDiscount">
            Discount
            <p>
              {organisationData.organizationDiscount
                ? organisationData.organizationDiscount
                : "NA"}
            </p>
          </label>
          <p />
        </div>
        <div
          style={{
            marginBottom: "1rem",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            // width: "100%",
          }}
        >
          <label htmlFor="addressLine1">
            Address
            <p>
              {organisationData.organizationAddress
                ? organisationData.organizationAddress
                : "NA"}
            </p>
          </label>
          <label htmlFor="city">
            City
            <p>
              {organisationData.organizationCity
                ? organisationData.organizationCity
                : "NA"}
            </p>
          </label>
          <label htmlFor="state">
            State
            <p>
              {organisationData.organizationState
                ? organisationData.organizationState
                : "NA"}
            </p>
          </label>

          <label htmlFor="country">
            Country
            <p>
              {organisationData.organizationCountry
                ? organisationData.organizationCountry
                : "NA"}
            </p>
          </label>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%",
          height: "fit-content",
        }}
      >
        {/* <label htmlFor="isActive">
          Active Status
          <br />
          <br />
          <Switch
            name="isActive"
            className="react-switch"
            onChange={handleActiveChange}
            checked={state.isActive}
          />
        </label> */}
        <label htmlFor="created">
          Created
          <p>
            {organisationData.created &&
              Moment(organisationData.created).format("DD MMM YYYY")}
          </p>
        </label>
        {/* <label htmlFor="updated">
          Last updated
          <p>
            {organisationData.updated
              ? Moment(organisationData.updated).format("DD MMM YYYY")
              : "NA"}
          </p>
        </label> */}
        <br />
        <button
          type="submit"
          aria-disabled={enable ? true : false}
          style={{
            borderRadius: "5px",
            backgroundColor: enable ? "green" : "lightgray",
            cursor: enable ? "pointer" : "default",
          }}
        >
          Save Changes
        </button>
      </div>
    </Form>
  )
}
//,
